<template>
  <div>
    <q-card
      v-for="item in offers"
      :key="item.id"
      class="q-mb-sm"
    >
      <offer-object
        :data="item"
        use-max-count
        change-detection
      />
    </q-card>
  </div>
</template>

<script>
// Components
import OfferObject from './offer-object.vue'

export default {
  name: 'DynamicOrderProductCollection',
  emits: ['click'],
  components: {
    OfferObject
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    offers () {
      return this.data.map(x => {
        return {
          ...x,
          ...x._embedded.productOffer
        }
      })
    }
  },
  methods: {
    handleClick (data) {
      const event = {
        type: 'Orderadmin\\Storage\\Entity\\Item',
        event: 'storage.assembling.scan.item',
        data: {
          ...data,
          componentType: 'collection'
        }
      }

      this.$emit('click', event)
    }
  }
}
</script>
